/*-------------------------------------------------------------------------------------------------------------------*/

    /* BUTTONS */

.button { //button
    padding: 1.5rem;
    cursor: pointer;
    font-weight: 800;
    border-radius: 4px;
    width: fit-content;
    @include type_p;
    margin-top: 2rem;
}

//small
.button_small {
    padding: 1rem;
}

//primary
.button_primary { //button_primary
    border: 0;
    background-color: $brand-01;
    color: $ui-01;

    &:hover { 
        background-color: $brand-hover;
    }
    
    &:disabled {
        background-color: $brand-disabled;
    }
    @at-root a#{&} {
        display: inline-block;
    }
}

.button_primary--full-width {
    @extend .button_primary;
    width: 100%;
}

.button_primary--half-width {
    @extend .button_primary;
    width: 35%;
}

//secondary
.button_secondary {
    border: 1.5px solid $brand-01;
    background-color: $ui-01;
    color: $brand-01;
    
    &:hover {
        background-color: $brand-hover;
        color: $ui-01;
        border-color: $brand-hover;
    }

    &:disabled {
        border: 1.5px solid $brand-disabled;
        color: $brand-disabled;
    }
}

//danger
.button_primary_danger {
    background-color: $danger;
    
    &:hover {
        background-color: $danger-hover;
    }

    &:disabled {
        background-color: $danger-disabled;
    }
}

.button_secondary_danger { //button_secondary_danger
    border: 1.5px solid $danger;
    color: $danger;
    
    &:hover {
        background-color: $danger-hover;
        color: $ui-01;
        border-color: $danger-hover;
    }

    &:disabled {
        background-color: $danger-disabled;
        color: $danger-disabled;
    }

}

//ghost
.button_ghost { //button_ghost
    background-color: $ui-01;
    color: $brand-01;
    border: 0;
        
    &:hover  { 
        background-color: $ui-08;
        color: $brand-hover;
    }

    &:disabled {
        background-color: $ui-01;
        color: $brand-disabled;
    }
}
.tpa-enrollee-payment-button {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.tpa-enrollee-payment-button button {
    margin-right: 10px;
}
/*-------------------------------------------------------------------------------------------------------------------*/

    /* PHONES */

@media screen and (max-width: 768px) {

}