/*-------------------------------------------------------------------------------------------------------------------*/

    /* TABLES */ 

    .table-wrap {
        display: flex;
        flex-direction: column;
        padding: 4rem 9rem;
    }

    #loader_custom {
        position: absolute;
        display: flex;
        background: #fff;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        right: 0;
        left: 0;
    }

    .table {
        width: 100%;

        th {
            background-color: $brand-01;
            color: #fff;
            height: 5rem;
            min-height: 5rem;
            font-size: 1.4rem;
            text-align: left;
            padding: 1rem;
            vertical-align: middle;
            line-height: 2rem;
            &:nth-child(2) {
                text-align: left;
            }
    
            &:nth-child(3) {
                text-align: left;
            }
            &:nth-child(4) {
                text-align: left;
            }
        }

        td {
            height: 5rem;
            min-height: 5rem;
            font-size: 1.4rem;
            text-align: left;
            padding: 1rem;
            color: #212121;
            vertical-align: middle;
            line-height: 2rem;
            font-weight: 600;
            color: #333;
            &:nth-child(1) {
                width: 25%
            }
    
            &:nth-child(2) {
                width: 25%;
                text-align: left;
            }
    
            &:nth-child(3) {
                width: 25%;
                text-align: left;
            }
            &:nth-child(4) {
                width: 25%;
                text-align: left;
            }
        }
    }

    .quater_one_table {
        width: 100%;
        margin-bottom: 4rem;
        th, td {
            &:nth-child(1) {
                width: 20%;
            }
            &:nth-child(2) {
                text-align: left;
                width: 35%;
            }
            &:nth-child(3) {
                text-align: left;
                width: 22%;
            }
    
            &:nth-child(4) {
                text-align: left;
                width: 22%;
            }
        }
    }

    .table-item {
        height: 5rem;
        min-height: 5rem;
        font-size: 1.4rem;
        text-align: left;
        padding: 1rem;
        color: #212121;
        vertical-align: middle;
        line-height: 2rem;
    }

    .table-header {
        padding: 2rem 0;
        font-size: 2.4rem;
    }

    .table-item__header {
        font-weight: 800;
    }

    .table-item__header-main {
        background-color: $brand-01;
        color: $ui-01;
    }

    .table-item__body {
        font-weight: 600;
        color: $text-03;
    }

    .table-item__body-header {
        background-color: rgba(81, 153, 211, 0.08);
    }

    .table-overflow {
        overflow-x: scroll;
    }

    .table {
        tr:hover {
            background-color:$ui-08;
        }
    }

    .table-striped {
        tr:nth-child(odd) {
            background-color: rgba(251, 251, 251, 0.5);
        }

        tr:nth-child(even) {
            background-color: rgba(242, 242, 242, 0.5);
        }
    }

    .table__warning-text {
        p {
            font-weight: 600;
        }
        a {
            text-decoration: underline;
            color: #107BC0;
        }
    }


    .table__footnote {
        color:#107bc0; 
        font-weight:800; 
        text-decoration: underline;
        font-size: 1.2rem;
        vertical-align: super;
    }



/*-------------------------------------------------------------------------------------------------------------------*/

    /* MOBILE VIEW QUERIES !!! */

    /* TABS */

    @media screen and (max-width: 1024px) {
        .table-wrap {
            padding: 4rem;
        }
    }

/*-------------------------------------------------------------------------------------------------------------------*/

    /* PHONES */

    @media screen and (max-width: 768px) {
        
        .table-wrap {
            padding: 2rem;
        }
        .table-exclusion-content {
            width: 100%;
        }
    }

    // exclusion list section

    .table-exclusion-content {
        td:nth-child(2) {
            width: 8%;
          }
    }
    .table-overflow {
        tr:hover {
            background-color:$ui-08;
        }
        .pre-conditions {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 20px;
            color: #AB0404;
            padding: 12px;
        }
    }

    .table-exclusions {
        width: 100%;
        td:nth-child(1) {
            width: 57%;
            text-align: left;
          }
    }