/*-------------------------------------------------------------------------------------------------------------------*/

    /* footer */

    .footer-container {
        min-height: 50rem;
        background-color: $brand-01;
        margin-top: 5rem;
        display: flex;
        align-items: center;
        @include type_p;
    }

    .footer-nav-wrap {
        display: flex;
        min-width: 70rem;
        justify-content: space-between;
    }

    .footer-nav-block {
        text-align: left;
        width: 15rem;
    }

    .footer-nav__list-item_header {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height: 1.7rem;
        color: $ui-01;
        margin-bottom: 1.6rem;
        font-weight: 600;
    }

    .footer-nav__list-item {
        text-transform: capitalize;
        line-height: 1.7rem;
        padding: 5px 0;
        font-weight: 300;

        a {
            color: $ui-01;
        }
    }

    .footer-nav__text-item {
        line-height: 2rem;
        font-weight: 300;
        color: $ui-01;
        padding-bottom: 2rem;

        a {
            color: $ui-01;
        }
    }

    /* mini footer */

    .mini-footer-container {
        height: 12rem;
        background-color: $ui-03;
        align-items: center;
    }

    .copyright {
        font-size: 1.4rem;
        color: $text-03;
    }

    .rhmo-social-links {
        display: flex;
        justify-content: space-between;

        a {
            font-size: 2.4rem;
            color: $text-03;
        }
    }

/*-------------------------------------------------------------------------------------------------------------------*/

    /* tab footer */ 

    @media screen and (max-width: 1024px) {

        .footer-container {
            padding:  0 2rem;
        }

    }

/*-------------------------------------------------------------------------------------------------------------------*/

    /* mobile phones */

    @media screen and (max-width: 768px ){
        
        .footer-container {
            padding: 4rem 0;
        }

        .footer-logo {
            height: 3rem;
        }

        .footer-nav-wrap {
            min-width: auto;
            flex-wrap: wrap;
            height: 54rem;
            justify-content: flex-start;
        }
        
        .footer-nav-block {
            width: 14rem;
        }

        .mini-footer-container {
            padding: 0px 2rem;
            flex-wrap: wrap;
        }

        .copyright {
            font-size: 1.2rem;
        }

        .rhmo-social-links {

            a {
                font-size: 1.4rem;
            }
        }
    }
