/* cart  */

/* mini cart bar */

.page-cart { //page-cart
    position: fixed;
    flex-direction: column;
    width: 100%;
    margin-top: 9.5rem;
    background: white;
    display: flex;
    bottom: 0;
    z-index: 9999;
}
    
.page-cart__header { //page-cart__header
    display: flex;
    width: 100%;
    padding: 1rem 0;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid $brand-01;
    cursor: pointer;
}

.page-cart__header-title { //page-cart__header-title
    color: #212121;
    @include type_h5;
    font-weight: 800;
}

.page-cart__body { //page-cart__body
    padding: 2rem 0; //edited to fit
    display: flex;
    width: 100%;
    flex-direction: column;
}

.page-cart__list { //page-cart__list
    display: flex;
    width: 100%;
    flex-direction: column;
}

.page-cart__list-item { //page-cart__list-item
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.page-cart__extension-text {
    color: $text-03;
    font-size: 1.4rem;
    margin: 1rem 0;
    font-weight: 500;
}

.page-cart__extension-button {
    margin-left: 1rem;
    padding: 1rem;
    border-radius: 4px;
    border: 0;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
    background-color: $ui-08;
    color: $text-03;
}

.page-cart__extension-button:hover {
    color: $ui-08;
    background-color: $text-03;
}

.page-cart__body__text {
    font-weight: 500;
    text-transform: capitalize;
}

.page-cart__button {
    width: 18rem;
    margin-left: 4rem;
    margin-top: 0;
}

.page-cart__footer { //page-cart__footer
    height: 8rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}

.page-cart__list-group {  //page-cart__list-group
    margin: 0.5rem 0;
}

.page-cart__list-group-title { //page-cart__list-group-title
    font-size: 1.2rem;
    color:  $ui-06;
    padding: 0.5rem 0;
    text-transform: uppercase;
}



/* cart page*/

.cart { //cart
    display: flex;
    margin-top: 8rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
}

.cart__header { //cart__header
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $ui-05;
    padding: 2rem 0;
    align-items: flex-end;
    width: 100%;
}

.cart__header-text { //cart__header_text
    width: 40rem;
    color:$brand-01;
}

.cart__payment-frequency { //cart__payment-frequency
    display: flex;
    flex-direction: column;
    
    p {
        font-weight: 500;
    }
}

.cart__content-switcher { //cart__content-switcher
    max-width: 50rem;
}

.cart-item__header {//cart-item__header 
    display: flex;
    height: 8rem;
    align-items: center;
    border-bottom: 1px solid rgba(242,242,242,0.69);
    font-size: 1.6rem;
    color: #212121;
    justify-content: space-between;
    width: 100%;
}

.cart-item__col-one {
    min-width: 45rem;
}


.cart-item__col-two {
    min-width: 20rem;
    text-align: right;
}

.cart-item__col-three {
    min-width: 4rem;
    text-align: right;
}

.cart-item { //cart-item
    display: flex;
    width: 100%;
    min-height: 8rem;
    padding: 0.5rem 0;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
}

.cart-item__wrap{ //cart-item__wrap
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 1rem 0;
}

.cart-item__plan-name {
    font-weight: 600;
    text-transform: capitalize;
}

.cart-item__price_unit-price {
    font-size: 1.4rem;
    margin-top: 0.5rem;
    color: $text-02;
    letter-spacing: 0.5px;
}

.cart-item__mini-button {
    width: 3rem;
    height: 3rem;
    border: 1.5px solid $brand-01;
    background-color: $ui-01;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
}

.cart-item__price_subtotal-price {
    font-weight: 300;
    letter-spacing: 1px;
}

.cart-total__border { //cart-total
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    flex-direction: column;
    padding: 1rem 0;
}

.cart-total__wrap { //cart-total__wrap
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5rem 0;
    align-items: center;
}

.cart-total__text {
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: 2px;
    color: $text-02;
}

.cart-total__price {
    color: $brand-01;
}

.cart-total__button { //cart-total__button
    @extend .page-cart__footer;
    justify-content: space-between;
    button {
        width: 20rem;
        margin-top: 0;
    }
}






/* tab  */ 

@media screen and (max-width: 1024px) {

    .page-cart__body, .page-cart__header {
        padding: 2rem;
    }

    .cart {
        padding: 1rem ;
    }

}


/* mobile */

@media screen and (max-width: 768px ){
    
    .page-cart__body, .page-cart__header {
        padding: 1rem;
    }

    .page-cart__button {
        width: 14rem;
        margin-left: 2rem;
    }

    .page-cart__button_cart-item {
        max-width: 12rem;
        min-width: 12rem;
    }

    .page-cart__header-title {
        font-size: 1.6rem;
    }

    .page-cart__list-group-title {
        font-size: 1rem;
    }

    .page-cart__extension-text {
        font-size: 1.2rem;
    }

    .page-cart__extension-button {
        padding: 0.7rem;
        font-size: 1.2rem;
        margin-top: 0.4rem;
    }

    .page-cart__footer {
        height: 6rem;
    }  

    .cart__header {
        flex-wrap: wrap;
    }

    .cart-item__header {
        font-size: 1.2rem;
    }

    .cart-item__col-one {
        min-width: 11rem;
    }

    .cart-item__col-two {
        min-width: 8rem;
        margin: 0 2px;
    }

    .cart-item__col-three {
        min-width: 3rem;
    }

    .cart-item__plan-name {
        font-size: 1.2rem;
    }

    .cart-item__price_unit-price , .cart-item__price_subtotal-price {
        letter-spacing: 0;
        font-size: 1.1rem;
    }

    .cart-item__mini-button {
        width: 22px;
        height: 22px;
    }

    .cart-total__text {
        font-size: 1.6rem;
        letter-spacing: 0px;
    }

    .cart-total__price {
        font-size: 1.8rem;
    }

    .cart-total__button {
        button {
            width: 12rem;
        }
    }
   

}


