//plan cards
.pricing-container { //pricing-container
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
}

.plan__text { //plan__text
    @include type_p;
    max-width: 80rem;
    text-align: center;
    color: $text-03;
    margin: 1rem;
}

.plan-duration {  //plan-duration
    display: flex;
    align-items: center;
    flex-direction: column;
}

.plan__wrapper { //plan__wrapper
    display: flex;
    align-self: center;
}

.plan { //plan
    min-height: 80rem;
    color: $text-01;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background-color: $ui-08;
}

.plan__button {
    width: 100%;
    color: $ui-01;
}

.plan__title { //plan__title
    @include type_h4;
    padding-top: 2rem;
    text-transform: uppercase;
}

.plan__price { //plan__price
    display: flex;
    align-items: center;
    height: 8rem;
    padding: 2rem 0;
    border-top: 2px solid $brand-01;
    border-bottom: 2px solid $brand-01;
    margin-top: 8px;
}

.plan__price_large { //plan__price_large
    @include type_h1;
    align-self: center;
}

.plan__price_small { //plan__price_small
    @include type_h5;
    line-height: normal;
    padding-left: 4px;

    @media screen and (max-width: 768px ) {
        line-height: normal;
    }
}

.plan__hospital-list {
    @include type_p;
    font-weight: 800;
    text-decoration: underline;
    color: $text-01;
}

.plan__input { //plan__input
    color: $text-02;
    font-weight: 600;
    margin-top: 2rem;
}

.plan__benefit-list { //plan__benefit-list
    padding-top: 2rem;

    li {
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 600;
        padding: 1rem 0;

        b {
            font-weight: 900;
        }
    }

}

.plan__benefit-note { //plan__benefit-note
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: $text-01;
    text-align: center;
    flex-direction: column;
}

.plan_redberyl { //plan_redberyl
    border: 1.5px solid $brand-04;

    .plan__price,
    .plan__input {
        border-color: $brand-04;
    }

    .plan__title, 
    .plan__price_large, 
    .plan__price_small {
        color: $brand-02;
    }

    .plan__button {
        background-color: $brand-02;
    }

    .sme-plan__item {
        .common-title-text {
            color: #107bc0;
        }
    }

    .sme-plan__button {
        background-color: #107bc0;
    }

    .sme-plan__input {
        border-color: #107bc0;
    }
}


.plan_alexandrite { //plan_alexandrite
    border: 1.5px solid $brand-02;

    .plan__price,
    .plan__input {
        border-color: $brand-02;
    }

    .plan__title, 
    .plan__price_large, 
    .plan__price_small {
        color: $brand-01;
    }

    .plan__button {
        background-color: $brand-01;
    }

    .sme-plan__item {
        .common-title-text {
            color: #094063;
        }
    }

    .sme-plan__button {
        background-color: #094063;
    }

    .sme-plan__input {
        border-color: #094063;
    }
}

//end of plan cards

@media screen and (max-width: 1024px) {


}

@media screen and (max-width: 768px) {

    .pricing-container {
        padding: 0 2rem;
        padding-top: 8rem;
    }

    .plan__text {
        font-size: 1.4rem;
        line-height: 2rem;
        width: 100%;
        align-self: center;
        max-width: fit-content;
    }

    .plan__text_notice {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    .plan__wrapper {
        flex-direction: column;
    }

    .plan { //plan
       // margin: 1rem 0;
        min-height: 75rem;
    }

    .plan__title {
        font-size: 2rem;
        line-height: 3.2rem;
    }

    .plan__price {
        height: 6rem;
        margin-top: 6px;
    }

    .plan__price_large {
        font-size: 3.2rem;
    }

    .plan__price_small {
        font-size: 1.2rem;
    }

    .plan__benefit-list {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .plan__input {
        height: 5rem;
        font-size: 1.4rem;
    }

    .plan__benefit-list {
        li {
            font-size: 1.4rem;
            line-height: 2rem;
            padding: 0.5rem 0;
        }
    }

    .plan__hospital-list {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .plan__benefit-note {
        font-size: 1.4rem;
       // padding: 0;
    }


}