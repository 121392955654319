$text-01: #212121; 
$text-02: #4F4F4F; 
$text-03: #333333; 

//appear in backgrounds etc
$ui-01: #FFFFFF;
$ui-02: #FDFEFE;
$ui-03: #F2F2F2;
$ui-04: #E6F0F9;
$ui-05: #E5E5E5;
$ui-06: #C4C4C4;
$ui-07: #FBFBFB;
$ui-08: #F9FAFC;
$ui-09: #819FB1;
$ui-10: #FBFCFD;
$ui-11: #EEE;

$brand-01: #094063; 
$brand-02: #107BC0; 
$brand-03: #5199D3; 
$brand-04: #89CBF0; 
$brand-05: #C0E5E5; 

$brand-hover: #003154;
$brand-focus: #093B5A;
$brand-disabled: #AAC2D8;

$danger: #AB0404;
$danger-hover: #920000;
$danger-focus: #9C0404;
$danger-disabled: #D88C8C;

//semantic colours
$success: #44D087;
$warning: #FFD144;
$info: #44A3E6;
$error: #D64444;

$color-black: #212121;
$color-white: #FFFFFF;
$color-blue-one: #094063;
$color-blue-two: #107BC0;
$color-blue-three: #5199D3;
$color-blue-four: #89CBF0;
$color-blue-five: #C0E5E5;
$color-grey-one: #333333;
$color-grey-two: #4F4F4F;
$color-grey-three: #cccccc;
$color-green: #11bd86;
$color-red: #ab0404;

