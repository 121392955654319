/* slider et carousel */

/* testimonial / text slider */

.testimonial-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $ui-01;
    cursor: pointer;
}

.testimonial-slider {
    display: flex;
    overflow-x: auto;
    padding: 4rem 0;
    text-align: center;
}

.text-slider { //text-slider
    width: 100%;
    min-height: 25rem;
    position: relative;
    margin-bottom: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        max-width: 70rem;
    }

}

.text-slider__item { //text-slider__item
    display: none;

    &.show {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.text-slider__control_prev { //text-slider__control_prev
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    color: $brand-01;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    left: 0;
    border-radius: 0 3px 3px 0;
}

.text-slider__control_next { //text-slider__control_next
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    color: $brand-01;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    right: 0;
    border-radius: 3px 0 0 3px;
}

.text-slider__dot-wrapper { //text-slider__dot-wrapper
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
}

.text-slider__dot { //text-slider__dot
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 4px;
    background-color: #CCDBE9;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.text-slider__dot_active, .text-slider__dot:hover { //text-slider__dot_active
    background-color: $brand-01;
}

.testimonial-name {
    @include type_p;
    margin-top: 1rem;
    font-weight: 800;
    color: #212121;

    a {
        color: #212121;
        cursor: pointer;

        &:hover {
            color: #107bc0;
        }
    }
}


/*---------------------------------------------------------------------------------------------------*/


/* image slider / carousel  */ 

.team-carousel {
    position: relative;
    padding: 6rem 0;
    max-width: 1100px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin: auto;
    display: flex;
}

.image-slider { //image-slider 
    display: none;
}

.image-slider__control_prev, .image-slider__control_next { //image-slider__control_prev
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: 80px;
    height: 80px;
    padding: 2rem;
    color: white;
    font-size: 24px;
    transition: 0.6s ease;
    background-color: #107BC0;
    border-radius: 2px;
    user-select: none;
    text-align: center;
}

.image-slider__control_prev {
    left: 0%;
}

.image-slider__control_next { //image-slider__control_next
    right: 0%;
}

.image-slider__dot { //image-slider__dot
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #CCE8FF;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    &:hover, &.active {
        background-color: #107BC0;
    }
}

.image-slider__dot-wrapper { //image-slider__dot-wrap
    text-align: center;
    padding: 2rem;
}

.fade { 
    animation-name: fade;
    animation-duration: 1s;
}

@keyframes fade {
    from {opacity: .2} 
    to {opacity: 1}
}





/* tab  */ 

@media screen and (max-width: 1024px) {


    .image-slider {
        img {
            max-height: 42rem;
            max-width: 100%
        }
    }


}


/* mobile */

@media screen and (max-width: 768px ){
    
    .text-slider {
        margin-bottom: 2rem;
    }

    .testimonial-name {
        font-size: 1.4rem;
        margin-top: 2rem;
    }

    .text-slider__control_prev, .text-slider__control_next {
        font-size: 1rem;
    }
    

    .text-slider {
       p {
        padding: 0 2rem;
       }
    }


    .image-slider {
        img {
            max-height: 27.5rem;
            min-height: 12.5rem;
            max-width: 100%
        }
    }

    .image-dot{
        height: 8px;
        width: 8px;
    }

    .image-slider__control_next, .image-slider__control_prev {
        top: 38%;
        width: 28px;
        height: 28px;
        padding: 0;
        font-size: 14px;
    }

    .team-carousel {
        padding: 3rem 0;
    }

    .image-slider__dot-wrapper {
        padding: 1rem;
    }

}

