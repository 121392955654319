
/* modal */ 

.modal {
  display: none; 
  position: fixed;
  z-index: 9999; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); 
}

.modal.show {
  display: block;
}
  
/* Modal Content/Box */
.modal__content {
  background-color: $ui-01;
  margin: 10% auto;
  padding: 2rem;
  width: 62%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
  
/* The Close Button */
.modal__close-btn {
  color: $text-02;
  font-size: 20px;
  font-weight: bold;
  align-self: flex-end;
}
    
.modal__close-btn:hover,
.modal__close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
  
  
.modal__header {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #CCDBE9;
}

.modal__illustration {
  padding: 2rem;
  height: 20rem;
}

.exit-popup {
  display: block;

  .modal__content {
    width: 45%;
  }
}
    
.exit-popup-wrap {
  width: 100%;
  align-self: center;
  text-align: center;
}

.provider__list {
  height: 53rem;
  overflow: scroll;
}

@media screen and (max-width: 768px) {
  .modal__content {
    width: 90%;
  }

  .exit-popup {
    .modal-content {
      width: 90%;
    }
  }

  .modal__illustration {
    padding: 1rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .modal__illustration {
    height: 0;
  }
}

/*

//  HTML

<!-- MODAL-->

<div id="" class="modal">
<!-- Modal content -->
  <div class="modal__content">
  <div class="modal__header">
      <h1 class="">Wait! Dont go</h1>
      <span class="modal__close-BTN">×</span>
  </div>

  <div>

  </div>
      //insert modal item here.
  </div>

</div>

// JS INLINE SCRIPT

<script>
  // Get the modal
  var modal = document.getElementById(" ");

  // Get the button that opens the modal
  var btn = document.getElementById(" ");

  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("modal__close-btn")[0];

  // When the user clicks the button, open the modal 
  btn.onclick = function () {
      modal.style.display = "block";
  }

  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
      modal.style.display = "none";
  }

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
      if (event.target == modal) {
          modal.style.display = "none";
      }
  }
</script>
*/