//number of columns in the grid
$columns: 12;

// breakpoints; based on values we currently use
// added new breakpoint so we can do better work 

// deskop = .lg             >= 1280px
// tabs & co = .md          >= 1024px
// mini tabs & co = .sm     >= 768px
// phones & co = .xs        <= 767px

$breakpoints: (
    "sm": 768px,
    "md": 1024px,
    "lg": 1280px,
);

$breakpoint: (
    "xs": 767px,
);


//function that calculates column width per breakpoint size
@function calcWidth($n) { 
    @return calc((#{$n} / 12 * 100%));
}

.col {
  padding: 12px; //padding here serves as 'gutter'
}

.row { // use row when you want the classes to wrap
    display: flex;
    flex-wrap: wrap;
}

//to generate the sizes of our columns and create the classes we use

@each $key, $value in $breakpoints {
    @media screen and (min-width: $value) { 
        @for $i from 1 through 12 {
            .#{$key}-#{$i} {
                flex-grow: 0;
                width: calcWidth($i);
		 	}
		}
	}
}

@each $key, $value in $breakpoint {
    @media screen and (max-width: $value) { 
        @for $i from 1 through 12 {
            .#{$key}-#{$i} {
                flex-grow: 0;
                width: calcWidth($i);
		 	}
		}
	}
}


/*

its kinda like boostrap

where the html looks like this
<div class="row">
    <div class="col lg-3 md-6 sm-12 xs-12">2</div>
    <div class="col lg-3 md-6 sm-12 xs-12">3</div>
    <div class="col lg-3 md-6 sm-12 xs-12">4</div>
    <div class="col lg-3 md-6 sm-12 xs-12">5</div>
</div>

classes generated:
.lg-1 .lg-2 .lg-3 .lg-2 .lg-5 .lg-6 .lg-7 .lg-8 .lg-9 .lg-10 .lg-11 .lg-12
.md-1 .md-2 .md-3 .md-2 .md-5 .md-6 .md-7 .md-8 .md-9 .md-10 .md-11 .md-12
.sm-1 .sm-2 .sm-3 .sm-2 .sm-5 .sm-6 .sm-7 .sm-8 .sm-9 .sm-10 .sm-11 .sm-12
.xs-1 .xs-2 .xs-3 .xs-2 .xs-5 .xs-6 .xs-7 .xs-8 .xs-9 .xs-10 .xs-11 .xs-12

*/