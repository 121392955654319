/*-------------------------------------------------------------------------------------------------------------------*/
    
    /* header header*/

    .header { //header
        margin-top: 9rem;
        width: 100%;
        min-height: 64rem;
    }

    .header__text-box_left { //header__text-box_left
        max-width: 67rem;
        text-align: left;
        margin-top: 6rem;
    }

    .header__text {
        @include type_h1;
        font-weight: 900;
        color: $brand-02;
    }

    .header__text--black {
        @extend .header__text;
        color: $text-01;
    }

    .header__text-small {
        @include type_h5;
        color: $text-02;
        max-width: 38.6rem;
    }

    .header_center-align { 
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .header__text-box_center { //header__text-box_center 
        max-width: 100rem;
        padding-top: 8rem;
        text-align: center;
    }

    .header__text-small_center {
        @include type_h5;
        max-width: 90rem;
        margin: auto;
        margin-top: 1.2rem;
        color: $text-02;
    }

    .header__text-box_center--min-width {
        @extend .header__text-box_center;
        max-width: 70rem;
    }

    .header__text-small_center--margin-bottom {
        @extend .header__text-small_center;
        margin-bottom: 1.2rem;
    }

    .added-padding {
        padding: 20px 0;
    }

    .header__image { //header__image
        min-height: 30rem;
        max-height: 40rem;
        padding: 4rem 0px;
    }

    .header__button { //header__button
        width: 20rem;
    }

    .header__image_index { //header__image_index
        background: url(../img/index_desktop_ver.svg) no-repeat;
        background-size: cover;
        background-position: center bottom;
    }

    .header__image_family { //header__image_family
        background: url(../img/family_desktop_ver.svg) no-repeat;
        background-size: cover;
        background-position: center bottom;
    }



/*-------------------------------------------------------------------------------------------------------------------*/

    /* TABS */

    @media screen and (max-width: 1024px) {
        .header__image_index {
            background-size: contain;
        }

        .header__text {
            font-size: 3.6rem;
            line-height: 5rem;
        }

        .header__text-small {
            max-width: 36rem;
        }
    }


/*-------------------------------------------------------------------------------------------------------------------*/
    
    /* PHONES */

    @media screen and (max-width: 768px) {
        
        .header {
            //margin-top: 4rem;
            //padding: 0rem 2rem;
            min-height: 48rem;
        }

        .header__text {
            font-size: 3.2rem;
            line-height: 4rem;
        }

        .header__text-small {
            font-size: 1.6rem;
            line-height: 3rem;
        }

        .header__text-small_center {
            text-align: center;
        }

               
        .header__image_index, .header__image_family {
            background: url(../img/index_mobile_ver.svg) no-repeat;
            background-size: contain;
            background-position: center bottom;
            min-height: 55rem;
        }
        .header__image {
            width: 100%;
            object-fit: cover;
        }
    }