//type scale
//making them mixins, we just include them as needed. 

@mixin type_s { //p, small text
    font-size: 1.2rem;
    line-height: 1.8rem;

    @media screen and (max-width: 768px ) {
        font-size: 1.1rem;
        line-height: 1.65rem;
    }
}

@mixin type_p { //p size, body text, paragraph
    font-size: 1.6rem;
    line-height: 2.4rem;

    @media screen and (max-width: 768px ) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
}


@mixin type_h5 { //h5 size, title text
    font-size: 2.0rem;
    line-height: 3.0rem;

    @media screen and (max-width: 768px ) {
        font-size: 1.7rem;
        line-height: 2.55rem;
    }
}

@mixin type_h4 { //h4 size
    font-size: 2.4rem;
    line-height: 3.6rem;

    @media screen and (max-width: 768px ) {
        font-size: 2.3rem;
        line-height: 3.3rem;
    }
}

@mixin type_h3 { //h3 size
    font-size: 3.2rem;
    line-height: 4.8rem;

    @media screen and (max-width: 768px ) {
        font-size: 2.7rem;
        line-height: 4.05rem;
    }
}

@mixin type_h2 { //h2 size, subheading text, 
    font-size: 4.0rem;
    line-height: 6.0rem;

    @media screen and (max-width: 768px ) {
        font-size: 3.4rem;
        line-height: 5.1rem;
    }
}

@mixin type_h1 { //h1 size, heading text
    font-size: 4.8rem;
    line-height: 7.2rem;
    
    @media screen and (max-width: 768px ) {
        font-size: 4.3rem;
        line-height: 6.45rem;
    }
}