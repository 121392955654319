/*-------------------------------------------------------------------------------------------------------------------*/


//input text field

.tpa{
    &-payment-form {
        display: flex;
        justify-content: space-around;
        max-width: 1215px;
        margin-top: 4rem;
    }
    &-radio-button{
        display : inline-flex;
        align-items : center;
        height: 21px;
        --active : #fff;
        margin-right: 30px;
        input{
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 21px;
            margin-right: 10px;
            position: relative;
            &::before{
                display: inline-block;
                height: 21px;
                width: 21px;
                border-radius : 50%;
                border: 1px solid #094063;
                content: " "; 
            }
            &::after{
                display: inline-block;
                position: absolute;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                background: var(--active);
                content: " ";
                top: 4px;
                left: 4px;
            }
             &:checked {
                 --active : #094063;
            }


     
        }
    }
    &-select-options{
        display: block;
        width: 316px;
        height: 50px;
        font-size: 16px;
        border: 0.5px solid #7D7D7D;
        border-radius: 6px;
        padding: 0 10px;
        margin-top: 30px;
    }
    &-select-hide {
        display: none;
    }
    &-select-options-date {
        color: #C70D0D;
        font-size: 16px;
        margin-top: 10px;
        font-weight: 800;
    }
}
