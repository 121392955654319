/*-------------------------------------------------------------------------------------------------------------------*/

/* FORM ELEMENTS */ 

//input text field

.form__input { //form__input
    margin-top: 1rem;
    padding: 1.5rem;
    font-size: 1.6rem;
    border-radius: 4px;
    width: 100%;
    color: $text-02;
}

.form__input:hover, .form__inputfile-list:hover {
   border-color: $info; 
}

.form__input:focus, .form__inputfile-list:focus {
    outline-color: $info;
    -moz-outline-radius: 4px;
}

.form__input_style-one { //form__input_style-one
    
    background-color: $ui-01;
    border: 1.5px solid $ui-05;

}

.form__input_style-one_error {
    border: 1.5px solid $danger;
}

.form__help-text {
    color: #4b4b4b;
    @include type_p;
    margin-top: 1.5rem;
}

.form__input_style-two { //form__input_style-two
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1.5px solid $ui-05;
    border-radius: 4px 4px 0 0;
}

.form__input_style-two_error {
    border-bottom: 1.5px solid $danger;
}

.form__select { //select
    -webkit-appearance: none;
    background: url(../img/down_chevron.svg) no-repeat 95% 50%;
}

.form__label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #212121;
}

.form__button {
    width: 28rem;
}

.form__item-group { 
    font-size: 1.6rem;
    font-weight: 500;
    color: #212121;
    margin: 2rem 0rem;
    text-align: left;
}

.form__item-group--no-margin {
    @extend .form__item-group;
    margin: 0;
}

.form__inputfile-list {
    padding: 2rem;
    border: 1.2px solid $ui-05;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 0;

    li {
        margin-bottom: 1rem;
    }
}


.form__radio-button { //form__radio-button
    display: block;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
    
    label {
        padding: 1rem;
        padding-right: 32px;
        width: 100%;
        display: block;
        text-align: left;
        color: #212121;
        cursor: pointer;
        position: relative;
        z-index: 1;
        overflow: hidden;
        font-size: 1.6rem;
        font-weight: 600;
        border-radius: 4px;

        &:before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          content: '';
          background-color: rgba(68, 163, 230, 0.05);
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          z-index: -1;
        }
    ​
        &:after {
          width: 24px;
          height: 24px;
          content: '';
          border: 2px solid #094063;
          background-color: #fff;
          background-image: url(https://s3.us-west-2.amazonaws.com/cdn.reliancehmo.com/images/check.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 68%;
          border-radius: 10%;
          z-index: 1;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
        
    input:checked ~ label {
        
        &:before {
            transform: translate(-50%, -50%) scale3d(75, 56, 1);
            opacity: 1;
        }
        
        &:after {
            background-color: #094063;
            border-color: #094063;
        }
    }
        
    input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        // visibility: hidden;
        // opacity: 0;
      }

    p {
        @include type_p;
        width: 80%;
    }
}

.form__check-box {

    label {
  
        &:after {
          border-radius: 10%;
        }
    }

}



/*-------------------------------------------------------------------------------------------------------------------*/

/* MOBILE VIEW QUERIES !!! */

/* TABS */

@media screen and (max-width: 1024px) {
    

}

/*-------------------------------------------------------------------------------------------------------------------*/


/* PHONES */

@media screen and (max-width: 768px) {

    .form__input {
        padding: 1.5rem;
        font-size: 1.4rem;
    }

    .form__button {
        width: 100%;
    }

}
