/* navbar */

.navbar-container {
    background-color: $ui-01;
    width: 100%;
    display: flex;
    height: 8rem;
    position: fixed;
    top: 0;
    align-items: center;
    border-bottom: 1px solid #F8FBFD;
    z-index: 1000;
    left: 0; //new addition
}

.navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.navbar__menu {
    display: flex;
    align-items: center;
}


.navbar__list-item {
    padding-left: 2rem;

    a {
        @include type_p;
        color: $brand-01;

        i {
            font-size: 1rem;
        }
    }

    button {
        margin-top: 0;
        width: auto;
    }
}

.navbar__list-item:hover {
   a {
        color: $brand-02;
   }
}

.mobile-navbar {
    display: none;
}


/* tab navbar */ 

@media screen and (max-width: 1024px) {

    .navbar {
        padding: 0 2rem;
    }

}


/* mobile phones */

@media screen and (max-width: 768px ){

    .navbar-logo {
        height: 30px;
    }

    .main-navbar {
        display: none;
    }

    .mobile-navbar {
        display: block;
    }
    
    .mobile-navbar-trigger {
        cursor: pointer;
        display: inline-block;
        position: relative;
    
        span {
            background-color: $brand-02;
            display: block;
            height: 2px;
            margin-bottom: 4px;
            position: relative;
            top: 0;
            transition: all .3s ease-in-out;
            width: 20px;
        }
    }

    .mobile-navbar-trigger__menu-active span:first-child {
        top: 7px;
        transform: rotate(45deg);
    }

    .mobile-navbar-trigger__menu-active span:nth-child(2) {
        opacity: 0;
    }

    .mobile-navbar-trigger__menu-active span:last-child {
        top: -5px;
        transform: rotate(-45deg);
    }

    .mobile-navbar-menu {
        animation: fade-in 1s;
        background: $ui-01;
        border-radius: 0 0 4px 4px;
        display: none;
        left: 20px;
        padding: 2rem;
        position: absolute;
        top: 79px;
        right: 20px;
        text-align: left;
        box-shadow: 0px 20px 20px 0px rgba(51, 51, 51, 0.12);
    }

    .navbar__list-item {
        padding-left: 0;
        margin: 2rem 0;

    }

   


}
