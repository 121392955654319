/* Import Core */

@import "core/grid";
@import "core/fonts";
@import "core/reset-min";
@import "core/variables";
@import "core/mixin";
@import "core/typography";


/* Import Layout */

@import "layout/alignment";
@import "layout/content";
@import "layout/footer";
@import "layout/navbar";
 

/* Import Components */

@import "components/banners";
@import "components/buttons";
@import "components/cards";
@import "components/content-switcher";
@import "components/cart";
@import "components/dropdown";
@import "components/form";
@import "components/header";
@import "components/images";
@import "components/link";
@import "components/modal";
@import "components/sliders";
@import "components/table";
@import "components/tabs";


/* import page specific classes*/

@import "pages/login";
@import "pages/plan-pricing";
@import "pages/sme-plan";
@import "pages/provider-review";
@import "pages/refer";
@import "pages/tpa-payment";



* {
    font-family: 'Avenir LT Std', 'Helvetica Nueue', Helvetica, Arial, sans-serif;
    font-style: normal;
}

a {
    text-decoration: none;
}

html {
    font-size: 62.5%; /* base font size */
}

body {
    background-color: $ui-01;
}


.container {
    background-color: $ui-01;
    width: 100%;
    display: flex;
    overflow: auto;
    flex-direction: column;
}

.l-container { //l for layout
    max-width: 1240px;
    margin: 0 auto;
    width: 100%;
}


.featured-client-section{
   // flex-direction: column;
    // padding: 9rem;
    align-items: center; 
    padding: 1rem 0;
}


.featured-client-logo-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //padding: 4rem 0;

    .content-container__illustration {
        width: 40rem;
        border-radius: 4px;
    }
}


.featured-client-logo-section {
    //width: 20rem;
    height: 12.5rem;
      
}
    
.featured-client-logo-item {
    height: 7.5rem;
    margin: auto;
}

.featured-client-title-border {
    border-bottom: 1.5px solid #ccc;
    width: 150px;
    height: 1px;
    margin: 5rem 2rem;
}

.featured-client-title-text {
    color: $brand-03;
    text-transform: uppercase;
    font-size: 1.6rem;
    letter-spacing: 4px;
    margin-top: 4rem;
    font-style: normal;
    font-weight: 300;
}

/*-------------------------------------------------------------------------------------------------------------------*/
//formssss 

//get quote form
.get-quote {
    flex-direction: column;
    min-height: 40rem;
    background-color: $ui-04;
    justify-content: center;
    align-items: center;
    margin-bottom: -50px;

    h2 {
        text-align: center;
    }
}

.get-quote-form-container {
    margin-top: 18rem;
    padding: 0 9rem;
}

.get-quote-form-col {
    // width: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.get-quote__button {
    @extend .header__button;
}

.get-quote-form {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4rem 0;
}

//reimbursement
.reimburse-form {
    display: flex; 
    max-width: 50rem; 
    flex-direction: column; 
    margin: auto;
    padding-bottom: 4rem;
}



//hospital list search bar
.provider-search-bar-container {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: $ui-01;
    padding: 2rem 9rem;
}

//sign up form
.sign-up-form-wrapper {
    margin-top: 15rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}    

.sign-up-form {
    display: flex;
    flex-direction: column;
    max-width: 64rem;
    margin: 6rem 0;
}

.sign-up-form__group {
    display: inline-flex;
}

.sign-up-form__input-group {
    margin: 2rem 1rem;
    min-width: 30rem;
}

/*-------------------------------------------------------------------------------------------------------------------*/


.faq-container {
    background-color: $ui-07;
}

.faq-container__section-item {
    display: flex;
    flex-direction: column;
    width: 90rem;
    margin: 5px auto;
    text-align: justify;

    a {
        color: $brand-02;
    }

    h2 {
        margin-bottom: 1rem;
        text-align: left;
    }

    p {
        margin-bottom: 5px;
    }

    ul {
        margin: 1rem 0;
        li {
            list-style-type: disc;
            list-style-position: inside;
            list-style-image: url(../img/list-item.svg);
        }
    }
}

.privacy-terms-container {
    @extend .faq-container;
}

.privacy-terms__section-item {
    @extend .faq-container__section-item;
}

.team-members-container {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
}

.team-profile-container {
    width: 12rem;
    height: auto;
    position: relative;
    margin: 0.5rem;
}

.team-profile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    border-radius: 4px;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(63, 145, 196, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.team-profile-container:hover .team-profile-overlay {
    opacity: 1;
    cursor: pointer;
}

.team-profile-overlay__text {
    color: $ui-01;
    font-size: 1.3rem;
    text-align: center;
    line-height: normal;
}

.team-profile__img {
    width: 20rem;
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.board-member__img {
    height: 24rem;
    margin-top: 2rem;
    border-radius: 4px;
    filter: grayscale(100);
}
    
.board-member__text-box {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    
}

.board-member__wrap {
    align-items: end;
}

.board-member__name {
    color: #094063;
}

.board-member__role {
    @include type_p;
    color: #107bc0;
    padding-bottom: 0.5rem;
}

.board-member__text-item {
    padding-bottom: 1rem;
    text-align: justify;
}



.general-section {
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
} 


.text-danger {
    color: $danger;
}


.provider-list-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.provider-list__state-band {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    height: 5rem;
    // margin: 1rem 7rem;

    p {
        text-transform: uppercase;
    }
}

.provider-list__list-item {
    display: flex;
    width: 100%;
    padding: 1rem 7rem;
    justify-content: space-between;
}

.provider-list__list-item-details {
    display: flex;
    width: 60%;
    justify-content: space-between;
    flex-direction: column;
}

.provider-list__item-title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $text-01;
}

.provider-list__plan-type {
    width: 35%;
}


.affiliate-faq-section {
    margin-top: 2rem;
    height: 22rem;
    text-align: left;
    align-items: baseline;
}

.affiliate-faq-item {
    margin: 1rem 0;
}


   
.feedback-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 5rem;
    flex-wrap: wrap;
}

.feedback__item {
    width: 12rem;
    height: 16rem;
    padding: 2rem;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.feedback__item:hover {
    background-color: $ui-04;

    h2 {
        color: #094063;
    }
}

.provider-list-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.feedback__img {
    width: 8rem;
}

.feedback__text {
    font-size: 2rem;
    color: $brand-02;
}


.benefit-info {
    list-style: decimal;
    list-style-position: inside;

    li {
        font-weight: 300;
        padding: 1rem 0;

    }
}

.plan-package {
    height: 43rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .common-title-text {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }
}

.plan-package__img {
    height: 20rem;
    margin-bottom: 2rem;
}

.plan-package__button {
    width: 100%;
}


.core-values__item {
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .common-body-text {
        color: #212121;
        font-weight: 500;
    }

}
    

.plan-package-wrap {
    margin: 10rem 0;
    justify-content: space-between;
}

.plan-pricing__plan-wrapper {
    .pricing__content {
        display: flex;
        justify-content: space-between;
    }
}
      

/*-------------------------------------------------------------------------------------------------------------------*/


/* MOBILE VIEW QUERIES !!! */

/* TABS */

@media screen and (max-width: 1024px) {
    .general-section {
        padding: 4rem;
    }


    .get-quote-form-container {
        margin-top: 16rem;
        padding: 0 4rem;
    }

    .provider-list__list-item {
        padding: 1rem 4rem;
    }

}

/*-------------------------------------------------------------------------------------------------------------------*/


/* PHONES */

@media screen and (max-width: 768px) {

    .plan-pricing__plan-wrapper {
        .pricing__content {
            flex-wrap: wrap;
        }
    }

    .sub-header-text {
        font-size: 2.4rem;
        line-height: 3rem;
        padding: 4rem 2rem 2rem 2rem;
    }
      
    .featured-client-title-border {
        width: 50px;
        margin: 4rem 1rem;
    }

    .featured-client-title-text {
        font-size: 1.2rem;
        letter-spacing: 1px;
        margin-top: 3.5rem;
    }

    .featured-client-logo-item {
        height: 5.5rem;
    }

    .featured-client-logo-section {
        //width: 15rem;
        height: 10rem;
    }


    .faq-container {
        padding: 3rem 1rem;
    }

    .faq-container__section-item {
        width: auto;
    }

    .team-profile-container {
        width: 11rem;
    }

    .team-profile-overlay__text {
        font-size: 1.2rem;
    }

        
    .board-member__img {
        height: 22rem;
    }

    .general-section {
        padding: 4rem 2rem;
    }

    .get-quote-form-container {
        padding: 0 2rem;
    }

    .provider-list__list-item {
        padding: 1rem 0;
    }

    .provider-list__item-title {
        font-size: 1.6rem;
    }

    .affiliate-faq-item {
        margin: 1rem 0;
        width: 28rem;
        min-width: -webkit-fill-available;
        text-align: justify;
    }

    .affiliate-faq-section {
        margin-top: 1rem;
        height: 20rem;
    }

    .get-quote {
        min-height: 30rem;
    }

    .feedback__img {
        width: 6rem;
    }

    .feedback__item {
        width: 9rem;
        height: 12rem;
        padding: 1rem;
    }

    .feedback__text {
        font-size: 1.6rem;
    }
       
    .plan-package {
        // width: 100%;
        margin: 2rem 0;
        // min-width: auto;
        // max-width: -webkit-fill-available;

        //took the.btn class out

    }

    .plan-package-wrap {
       // flex-direction: column;
       margin: 0;
       justify-content: center;
    }

    .core-values-wrap {

        .content-container-wrap {
            align-items: center;
            width: 100%;
        }
    }

    .core-values__item {
        text-align: center;
    }

    .get-quote-form {
        flex-wrap: wrap;
    }

    .provider-search-bar-container {
        padding: 2rem;
    }

    .sign-up-form__group {
        flex-direction: column;
    }

    .sign-up-form {
        margin: 6rem 2rem;
    }

    .sign-up-form__input-group {
        margin: 2rem 0;
    }
}

// Custom css

@import "core/variables";

.loader {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.hide {
  display: none !important;
}

.l_select_extension {
  align-items: flex-start;
}

button:disabled {
  background-color: $color-grey-three !important;
}

.box-shadow {
  box-shadow: 0 0 1px 1px #eee;
}

.no-border {
  border: none !important;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.btn {
  a {
    color: white;
    text-decoration: none;
  }
}

.btn-small {
  padding: 0.5rem 1rem;
}

.btn-link {
  text-decoration: underline;
  color: $color-blue-one;
}

.text-danger {
  color: $color-red !important;
}

.text-success {
  color: $color-green
}

.text-muted {
  //font-size: 1rem;
  color: #cecece;
}

.text-left {
  text-align: left;
}

.text-brand-02 {
  color: $brand-02;
}

.login-card-container {
  justify-content: center;
}

// .accordion {

// }

.accordion .accordion-item {
  padding: 1rem 0;
}



.accordion {
  border: solid 1px $ui-11;
  margin-bottom: 1em;
  padding: 15px;
  border-radius: 4px;
  width: 100%;
}

.accordion__heading {
  margin-bottom: 0.5em;
  cursor: pointer;
}

.accordion__header {
  display: flex;
  justify-content: space-between;
}

.accordion__header:after {
  content:'-';
  font-size: 2rem;
  font-weight: bolder;
  margin-right: 1rem;
}

.accordion__divider {
  margin-bottom: 0.5em;
  background-color: $ui-11;
}

.accordion__body {
  margin-bottom: 1em;
}

.accordion.closed {
  .accordion__body {
    display: none;
    transition: visibility 1s, opacity 0.5s ease-out;
  }

  .accordion__header:after {
    content:'+';
  }
}

.accordion.open {
  .accordion__body {
    display: block;
  }

  .accordion__header:after {
    content:'-';
  }
}

.accordion .accordion-item .item-details {
  display: none;
}

.accordion .accordion-item.open .item-details {
  display: block;
}

.error-wrapper {
  padding: 2rem;
  font-size: 1.5rem;
  background-color: $color-red;
  //border-radius: 4px;
  color: white;
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  margin-top: 10rem;
  /* justify-content: space-between; */
  align-items: center;
  z-index: 9;

  .message {
    flex-grow: 1;
    text-align: center;
  }

  .close {
    padding: 1rem;
    cursor: pointer;
  }
}

.error-wrapper.show {
  display: flex;
}

.cartBtn {
  transition: background-color 0.3s ease;
}

.cartBtn.added {
  background-color: $color-green;
  color:white;
}

.checkout_con {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  background: white;
}


.page-cart-header {
  cursor: pointer;
  display: none;
  justify-content: flex-start;

  .updated {
    display: none;
    color: green;
    font-size: 1.5rem;
    font-style: italic;
  }

  .updated.show {
    display: inline-block;
  }
}

.page-cart-header.open {
  display: flex;
}

.page-cart-body {
  display: none;

  .checkout_small_text {
    text-transform: capitalize;
  }

  .group {

    .title {
      font-size: 1.3rem;
      font-weight: bolder;
      color: #d1d1d1;
      padding: 1rem;
    }
  }

  .clear{
    text-align: end;
    padding: 0.5rem 0;

    .clear-btn {
      border: 1px solid #ab0404 !important;
    }
  }
}

.page-cart-body.open {
  display: block;
}

.cart_item_plan {
  text-transform: capitalize;
}

.input-extra-group {
  position: relative;
  margin-bottom: 1rem;

  input {
    padding-right: 40px;
  }

  .input-extra {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem;
    margin-top: 0.5rem;
    width: 50px;
    height: 100%;

    img {
      width: 40px;
    }
  }
}

.discount_alert {
  margin: 1rem 0;
}


// Add Extension Detail

.content_text {
  width: 50%;
}

.extension-details {

  .provider-selector {
    &#providerSelector {
      @extend .form__input, .form__input_style-one, .form__select;
      background: url(../img/down_chevron.svg) no-repeat 95% 50%, url(../img/search.svg) no-repeat 1% 50%;
      padding-left: 30px;
    }

    .selectize-input {
      @extend .form__input, .form__input_style-one, .form__select;
      background: url(../img/down_chevron.svg) no-repeat 95% 50%, url(../img/search.svg) no-repeat 1% 50%;

      input {
        font-size: 1.5rem;
        border: none;
        margin-left: 5%;
        &:focus {
          outline: none;
        }
      }
    }
  
    .selectize-dropdown {
      height: auto;      
  
      .selectize-dropdown-content{
        max-height: 150px;
        overflow-y: scroll;
        padding: 1rem;
        margin-top: 1rem;
        box-shadow: 0 4px 4px rgba(137,203,240,0.1);

        .optgroup-header {
          text-transform: capitalize;
          font-weight: bold;
          background: #eee;
          font-size: 1.5rem;
          padding: 0.5rem;
        }
    
        .option {
          font-size: 1.5rem;
          margin: 0.5rem 0;
          cursor: pointer;
          text-transform: capitalize;
        }
      }
    }
  }
}

.add-extension-detail {
  justify-content: space-between;
  padding: 2rem;
  padding-top: 0;
  max-height: 500px;
  min-height: 300px;
  overflow-y: scroll;

  .select {
    min-width: 18rem;
    margin-top: 0;
    width: 100%;

    .selectize-dropdown {
      height: auto;

      .selectize-dropdown-content{
        max-height: 150px;
      }
    }

    .selectize-input {
      border: none;
      box-shadow: none;
    }

    .optgroup-header {
      text-transform: capitalize;
      font-weight: bold;
      background: #eee;
    }
  }

  .input {
    padding: 1rem;
    border-radius: 4px;
    margin-top: 0;
  }

  p {
    margin: 1rem 0;
  }

  .plan-selector {
    margin: 2rem 0;
    align-items: center;

    .or {
      margin: 0 1rem;
    }

    #addNewPlanForExtension {
      background-color: white;
      margin:0;
      cursor: pointer;
    }
  }

  .plan-selector.hide {
    display: none;
  }

  .provider-selector {
    margin: 2rem 0;
  }

  .provider-selector.hide {
    display: none;
  }

  .extra {
    margin: 2rem 0;
  }

  .extra.hide {
    display: none;
  }

  .new-plan-selector {
    display: none;
    align-items: center;

    .cancel{
      padding: 0 1rem;
      display: none;

      button {
        background-color: white;
      }
    }

    .plan-item{
      padding: 2rem;
      margin: 2rem;
      min-width: 30rem;
      border-radius: 4px;
      min-height: 20rem;

      .name {
        color: white;
        font-size: 2.4rem;
        line-height: 3.6rem;
        font-weight: 800;
        padding-top: 2rem;
      }

      .price {
        font-size: 4rem;
        font-weight: 900;
        color: white;
        padding: 2rem 0;
        border-top: 2px solid #094063;
        border-bottom: 2px solid #094063;

        .freq {
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }
  }

  .new-plan-selector.show{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
  }

  .price {
    margin: 1rem 0;
  }

  .add-extension {
    display: none;
    text-align: end;
    padding: 0 1rem;
  }

  .add-extension.show {
    display: block;
  }
}

//cart summary
.cart-summary {
  width: 100%;

  .summary-group {
    padding: 1rem;

    .group__title {
      font-size: 1.2rem;
      color: #cecece;
    }

    .group__detail {
      padding: 1rem 0;

      .cart-group__header {
        cursor: pointer;
      }

      .cart-group__detail {
        display: none;
        background-color: #fbfbfb;
        padding: 1rem;
      }

      .cart-group__detail.open {
        display: flex;
      }
    }
  }

  .discount_notice {
    margin-top: 3rem;
    font-size: 1.5rem;
  }

  .cart-price-wrapper {
    display: flex;
    flex-direction: column;

    .cart_old_price {
      text-align: center;
      font-size: 1.5rem;
      text-decoration: line-through;
    }
  }
}



.hospital-list-wrapper {

  .search-bar {
    margin: 0.5rem 1.5rem;

    input {
      width: 100%;
      border:none;
      border-bottom: 1px solid #ecebeb;
      border-radius: 3px;
      padding: 0.5rem;
    }
  }
}

.hospital-list{

  padding: 1.5rem;
  max-height: 400px;
  overflow-y: scroll;

  .hospital-list-item {

    margin-bottom: 1rem;

    .hospital-name {
      font-size: 16px;
    }

    .hospital-address {
      font-size: 12px;
      color: #5a5a5a;
    }
  }

  .hospital-state {
    font-size: 1.5rem;
    font-weight: bolder;
    margin: 2rem -1.5rem;
    background: #f4f4f4;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
  }
}

.hospital_search_con {
  background-color: white;
  padding: 2rem 9rem;
}

.search_con_fixed {
  position: fixed;
  margin-top: 8rem;
}

.hospital_list_con_margin_top {
  margin-top: 10rem;
}

.hospital_list {
  flex-direction: column;
}


//tooltip styles
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-show-tooltip=yes]:before,
[data-show-tooltip=yes]:after {
  visibility: visible;
  opacity: 1;
}


.variable_bill_notice {
  font-size: 1.5rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  a {
    display: block;
  }

  p {
    margin: 1rem 0;
  }

  .content {
    display: flex;
  }

  .breakdown_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    .breakdown {
      padding: 1rem;

      .title {
        font-size: 1.3rem;
        font-weight: bolder;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: #383636;
      }
    }
  }

  .action_wrapper {
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    flex-direction: row;

    button {
      padding: 1rem 3rem;
      margin: 1rem;
      text-transform: uppercase;
    }
  }
}

.pre-existing-conditions-modal {
  @extend .variable_bill_notice;
}


.featured-clients-carousel { 
  padding: 5rem 0; 
  justify-content: center;
  margin: 10rem 0; 
  .logo-carousel {
    width: 100%;  
    margin: 5rem 0;  
    .slide {
      margin: 2rem 5rem;  
      img {
        height: 5rem;
      }  
    }
  }  
}

.job-card {
  padding: 20px 0;
}

.card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .job-title {
    @extend .common-body-text;
    text-decoration: none;
    margin-right: 3rem;
    pointer-events: none;
  }
  .mr-4 {
    display: none;
  }
  .mr-4 + div {
    display: none;
  }
  .fas {
    display: none;
  }
  hr {
    display: none;
  }
  .ml-auto a {
    width: 10rem;
    margin-top: 0;
    @extend .button, .button_small, .button_secondary;
  }

}

.margin-offset-header {
  margin-top: 3em;
}

.colored-banner__contained {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.nps-review-form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 4rem 1rem;
}

.nps-review-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4rem;

}

.nps-review-form__item-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 1.8rem;
    margin-bottom: 3rem;
    height: 8rem;
    max-width: 100%;
    padding: 10px 0;
    font-weight: 600;
    color: $text-02; 
  }
}

.nps-review-form__item {
  display: block;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

.nps-form__radio-button { 
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  position: relative;
  border-radius: 4px;
  
  label {
    padding: 1rem;
    width: 100%;
    color: $text-01;
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
  }
      
  input {
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  p {
    width: 80%;
  }
}

.benefit-card {
  background-color: rgba(251, 251, 251, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40rem;
  padding: 3rem;
  margin-bottom: 3rem;

  .subtitle-text--bold {
    margin-bottom: 2rem;
  }

  img {
    height: 10rem;
    margin-left: auto;
  }
}

.core-value-container {
  padding: 1rem 6rem;
}

.core-value__illustration {
  width: 100%;
}

.nps-form-comment-box {
  width: 100%;  
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
    height: 200px;
    background-color: #FCFCFC;
    border: 1px solid $ui-05;
    color: $text-02;
    padding: 2rem;
    font-size: 2rem;
  }

  label {
    padding: 3rem 0;
    font-size: 2rem;
    color: $text-02;
  }
}

.justify-center {
  text-align: center;
}

.header__text-small_center-style,
.nps-form_small__header  {
  max-width: 59rem;
  line-height: 4rem;
  font-size: 2rem;
}

.nps-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.form_options_wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: center;

  .nps-form__radio-button > label {
    vertical-align: middle;
    padding: 5px;
    
  }

  .nps-form__radio-button > input {
    vertical-align: middle;
    padding: 5px;
  }
}

.input-rating-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.rating-text {
  padding: 1rem 0;
  font-size: 1.6rem;
  color: $text-02;
}

.submit-nps-btn {
  width: 50rem;
}

.core-value-container {
  padding: 1rem 6rem;
}

.hear_about_us_select {
  margin-top: 24px;
}

.core-value__illustration {
  width: 100%;
}

/* PHONES */

@media screen and (max-width: 768px) {

  .row-flex {
    flex-wrap: wrap;
  }

  .row-flex-height {
    height: 14rem;
    justify-content: center;
  }
  
  .content_text {
    width: 100%;
  }

  .add-extension-detail {
    .select {
      width: -webkit-fill-available;
    }
    .input {
      width: -webkit-fill-available;
    }

    .plan-selector {
      #addNewPlanForExtension {
        width: -webkit-fill-available;
      }
    }

    .new-plan-selector .plan-item .price {
      font-size: 3rem;
    }

  }

  .hospital-list-wrapper {
    margin-top: -20px;

    .search-bar {
      margin:0;
      position: fixed;
      width:100%;

      input {
        padding: 1.5rem;
        font-size: 1.5rem;
        font-style: italic;
      }
    }

    .hospital-list{
      max-height: 100%;
      padding-top: 3rem;
      padding-bottom: 50px;
    }
  }

  .input_style_three {
    max-width: 300px;
  }

  .hospital_search_con {
    padding: 2rem 1rem;
  }

  .variable_bill_notice {
    .breakdown_wrapper {

      .breakdown {
        padding: 1rem 0;
      }
    }

    .action_wrapper {

      button {
        margin: 1rem 0;
      }
    }
  }
  .get-quote-sub-header-text {
    font-size: 3.2rem;
    line-height: 4rem;
    padding: 0;
    padding-top: 4rem;
  }

  .nps-review-form {
    width: 100%;
  }

  .form__radio-button {
    padding: 20px 0;
  }

  .form_options_wrapper {
    flex-flow: wrap;
    width: 100%;
    padding-left: 0;
  }

  .submit-nps-btn {
    width: 20rem;
  }

  .nps-review-form__item-group {
    margin-bottom: 2rem;
    width: 100%;

    p {
      font-size: 1.6rem;
      height: auto;
      margin-bottom: 2rem;
    }
  }

  .nps-form__radio-button {
    padding: 20px 0;
    margin-right: 3rem;

    input {
      width: 19px;
      height: 19px;
    }
  }

  .input-rating-label {
    font-size: 1.5rem;
  }

  .nps-form-comment-box label {
    font-size: 1.8rem;
  }

  .nps-form-comment-box textarea{
    font-size: 1.8rem;
  }

}


@media screen and (max-width: 600px) {
  .nps-form__radio-button {
    padding: 20px 0;
    margin-right: 1rem;
  }
  .core-value-container {
    padding: 1rem;
  }
}

