.justify-center {
    display: flex;
    justify-content: center;
}

.align-items-end {
    align-items: flex-end;
}

.margin-top {
    margin-top: 1em;
}

.margin-bottom {
    margin-bottom: 1em;
}
.mx-auto {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
