.referral-landing__header {
  height: 50em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.referral-landing-contact__form {
  @extend .refer-card-container;
  padding: 1em;
}

.referral-landing-contact__country-code {
  width: calc(10% - 1.5px);
  margin-top: 0;
}

.referral-landing-contact__phone-number {
  width: calc(59% - 1.5px);
  margin-top: 0;
}

.referral-landing-contact__submit {
  width: calc(27% - 1.5px);
  margin-top: 0;
}

ul.why-rhmo__list {
  padding: 0 3rem;
 
  li {
    list-style-type: disc;
    list-style-position: outside;
    list-style-image: url(../img/checkmark-bg.svg);
    margin-bottom: 1em;
  } 

  li::marker {
    margin-top:5px;
  }
}

ul.easy-way__list {
  padding: 0 3rem;
 
  li {
    list-style-type: disc;
    list-style-position: outside;
    list-style-image: url(../img/checkmark.svg);
    margin-bottom: 1em;
  } 

  li::marker {
    margin-top:5px;
  }
}

ul.hard-way__list {
  padding: 0 3rem;
 
  li {
    list-style-type: disc;
    list-style-position: outside;
    list-style-image: url(../img/crossmark.svg);
    margin-bottom: 1em;
  } 

  li::marker {
    margin-top:5px;
  }
}

.insurance-comparism__heading {
  text-transform: uppercase;
  font-size: 2.4rem;
  margin: 2em 0;
}

.insurance-comparism__tag {
  text-transform: uppercase;
  padding: 15px 30px;
  font-weight: 800;
}

.insurance-comparism__tag--red{
  @extend .insurance-comparism__tag;
  background-color:rgba(214, 68, 68, 0.04);
  color: #D64444;
}

.insurance-comparism__tag--blue{
  @extend .insurance-comparism__tag;
  background-color: rgba(16, 123, 192, 0.04);
  color: #44A3E6;
}

.referral__navigation {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 1em;
}

.refer-card-container {
  background: $ui-01;
  box-shadow: 0px 2px 40px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.easy-way__card {
  @extend .refer-card-container;
}

.insurance-comparism__holder {
  padding: 50px 25px;
}

.faq__question-body {
  margin-bottom: 1em;
}

.limited-invites__holder {
  background-color: $ui-08;
  text-align: center;
  padding: 15em 5em;
}

.limited-invites__heading {
  font-size: 2.5em;
}

.refer-container-constrained-width {
  padding: 0 25em;
}

.collect-referral-contact__subtext {
  font-size: 20px;
  margin-bottom: 1em;
}

.collect-referral-contact__holder {
  @extend .refer-container-constrained-width;
}

.collect-referral-contact__country-code {
  width: calc(25% - 1.5px);
}

.collect-referral-contact__phone-number {
  width: calc(74% - 1.5px);
}

.collect-referral-contact__form {
  @extend .refer-card-container;
  padding: 5em 1em;
}

.plan-benefits__holder {
  @extend .refer-card-container;
  text-align: center;
  padding: 5em 2em;
}

.plan-benefits__subtext {
  margin-bottom: 1em;
  padding: 0 10em;
  font-size: 20px;
}

.plan-benefits__switcher {
  display: flex;
  width: 70%;
  margin: 0 auto;
}

.plan-benefits-switch {
  width: 50%;
  padding: 20px 0;
  font-weight: 800;
  color: $ui-01;
  border: none;
}

.plan-benefits-switch__red-beryl {
  @extend .plan-benefits-switch;
  background-color: #70B6EF;
}

.plan-benefits-switch__alexandrite {
  @extend .plan-benefits-switch;
  background-color: #107BC0;
}

.plan-benefits__table {
  table-layout: fixed;
}

.plan-benefits__list-red-beryl {
  .table-item__header-main {
    background-color: #70B6EF;
    color: #fff;
  }
} 

.plan-benefits__list-alexandrite {
  .table-item__header-main {
    background-color: #107BC0;
    color: #fff;
  }
} 

.plan-benefits-list__referral-price {
  color: #107BC0;
  font-size: 18px;
  font-weight: bold;
  display: block;
}

.referral-confirm-payment__holder {
  @extend .refer-container-constrained-width;
}

.referral-confirm-payment__card {
  @extend .refer-card-container;
  padding: 1em 5em 5em 5em;
}

.referral-confirm-payment__subtext {
  font-size: 20px;
  margin-bottom: 1em;
}

.referral-confirm-payment__body-text {
  margin-bottom: 1em;
}

/* PHONES */

@media screen and (max-width: 768px) {
  .referral-landing-contact__country-code {
    width: calc(25% - 1.5px);
  }

  .referral-landing-contact__phone-number {
    width: calc(74% - 1.5px);
  }

  .referral-landing-contact__submit {
    width: 100%;
    margin-top: 10px;
  }

  .refer-container-constrained-width {
    padding: 0;
  }

  .collect-referral-contact__form {
    padding: 1em;
  }

  .limited-invites__holder {
    padding: 5em 1em;
  }

  .plan-benefits__subtext {
    padding: 0;
  }

  .plan-benefits__switcher {
    width: 100%;
  }

  .plan-benefits__table {
    table-layout: auto;
  }

  .referral-confirm-payment__card {
    padding: 1em;
  }
}