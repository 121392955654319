//provider review form
.provider-review-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4rem 0;

    &__button {
        align-self: center;
    }
}

//provider review form
.provider-review-form-wrap {
    margin-bottom: 4rem;
}

.provider-review-form__item-group {
    p {
        font-size: 2rem;
        margin-bottom: 1rem;
        height: 8rem;
    }
}

@media screen and (max-width: 768px) {

    .provider-review-form__item-group {
        margin-bottom: 2rem;

        p {
            font-size: 1.8rem;
            height: auto;
            margin-bottom: 2rem;
        }
    }
}
