//sme plan 

.sme-header__text {
    text-align: center;
    font-size: 3rem;
    padding: 4rem 0rem;
    color: #107BC0;
    font-weight: 800;
    line-height: 4rem;
}

.sme-plan {
    margin: 4rem 0;
    padding: 1rem;
    border-radius: 4px;
    background-color: $ui-08;
}

.sme-plan__item {
    display: flex;
    flex-direction: column;
    height: 25rem;
    justify-content: space-evenly;
}

.sme-plan__link-item {
    font-size: 1.4rem;
    font-weight: 600;
}

.sme-plan__title { //sme-plan__title
    font-size: 1.8rem;
    color: $text-01;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 800;
    height: 4rem;
}

.sme-plan__price {
    display: flex;
    //padding: 1rem 0;
    flex-wrap: wrap;
}

.sme-plan__price_large {
    font-size: 2.4rem;
    color: $text-03;
    font-weight: 800;
}
    
.sme-plan__price_small {
    font-size: 1.4rem;
    color: $text-03;
    padding-left: 3px;
}

.sme-plan__input {
    border: 1.5px solid $brand-01;
}

.sme-plan__button {
    width: 100%;

}

@media screen and (max-width: 1024px){
    .sme-plan__item {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 768px) {

    .sme-header__text {
        font-size: 2rem;
        padding: 2rem 0rem;
        line-height: 3.2rem;
    }

}
