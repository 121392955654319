/* content switcher*/

.content-switcher {
    max-width: 60rem;
    display: flex;
    align-self: center;
    margin: 1rem 0;
}

.content-switcher__btn {
    @include type_p;
    color: $text-01;
    border: 0;
    background-color: $ui-08;
    width: 20rem;
    font-weight: 300;
    margin-top: 0;
    border-radius: 0;
}    

.content-switcher__btn_active {
    font-weight: 800;
    background-color: $brand-01;
    color: $ui-01;
}

.content-switcher__btn_left {
    border-bottom-left-radius: 4px; 
    border-top-left-radius: 4px;
    border: 2px solid $brand-01;
    border-right: 0;
}

.content-switcher__btn_right {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border: 2px solid $brand-01;
    border-left: 0;
}

.content-switcher__btn_middle {
    border: 2px solid $brand-01;
    border-left: 0;
    border-right: 0;
}

.content-switcher__btn:hover {
    background-color: $brand-hover;
    border-color: $brand-hover;
    color: $ui-01;
}

/* tab  */ 

@media screen and (max-width: 1024px) {



}


/* mobile */

@media screen and (max-width: 768px ){
    
    .content-switcher__btn {
        // height: 5rem;
        width: 10rem
    }

}


