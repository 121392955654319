.login-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;
    height: 46rem;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 1px 2px 0px rgba(137,203,240,0.3);
}

.login-card__icon {
    height: 16rem;
}

.login-card__button {
    width: 100%;
}

/*----------------------------------------------------------------------------------------------------------------*/

/* TABS */

@media screen and (max-width: 1024px) {
 
    .login-card-container {
        flex-wrap: wrap;
        justify-content: space-around;
        min-height: 105rem;
    }


}