//banners 
/*
    where the html looks like this:

    <div class="banner banner_error">
        <div class="banner__bar">
            <i>&nbsp;</i>
        </div>

        <div class="banner__icon">
            <i class="fa fa-exclamation-circle"></i>
        </div>

        <div class="banner__message">
            You need to be 18 and above to sign up for a plan. If you wish to purchase a plan for a
            minor, please enter your own details as the guardian.
        </div>

        <div class="banner__close">
            <i class="fa fa-times"></i>
        </div>
    </div>
    
*/

.banner {
    border: 1px solid;
    border-radius: 4px;
    padding: 0.4rem;
    background-color: #ffffff;
    color: #212121;
    position: fixed;
    width: 80%;
    top: 0;
    margin-top: 9rem;
    display: flex;
    align-self: center;
    align-items: center;
    z-index: 9;
    height: 6rem;
}

.banner__bar {
    width: 3px;
    max-width: 3px;
    height: -webkit-fill-available;
    border-radius: 4px;
    margin-right: 1.3rem;
    background-color: transparent;
}

.banner__icon {
    margin-right: 2rem;
    font-size: 1.6rem;
    color: transparent;
}

.banner__message {
    text-align: left;
    flex-grow: 1;
    color: #212121;
    @include type_p;
}

.banner__close {
    padding: 1rem;
    margin-right: 1rem;
    cursor: pointer;

    .fa {
        font-size: large;
    }
}

//banner for errors
.banner_error {
    border: 1px solid $error;

    .banner__bar {
        background-color: $error;
    }
    .banner__icon {
        color: $error;
    }
}

//banner for info
.banner_info {
    border: 1px solid $info;

    .banner__bar {
        background-color: $info;
    }
    .banner__icon {
        color: $info;
    }
}

//warning
.banner_warning {
    border: 1px solid $warning;

    .banner__bar {
        background-color: $warning;
    }
    .banner__icon {
        color: $warning;
    }
}

//success
.banner_success {
    border: 1px solid $success;

    .banner__bar {
        background-color: $success;
    }
    .banner__icon {
        color: $success;
    }
}

/* tabs */ 

@media screen and (max-width: 1024px) {

}

/* mobile */ 

@media screen and (max-width: 768px) {
    .banner {
        height: 14rem;
        width: 90%;
    }

}